import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Select from "react-select";
import isNum from "./lib/isNum.js";
import numberConverter from "number-to-words";
import Modal from "./components/Modal.js";

import "./app.css";

import { isMobile } from "react-device-detect";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faBook,
  faMicrophone,
  faMicrophoneSlash,
  faVolumeHigh,
  faClose,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";

import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

import * as pdfjs from "pdfjs-dist";
import colors from "./colors.js";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  const [bookPDF, setBookPDF] = useState(null);
  const [language, setLanguage] = useState("en-US");
  const [translation, setTranslation] = useState("none");

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: bookPDF ? colors.white : colors.secondary,
      }}
    >
      {/* <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-2862497790933982"
        data-ad-slot="2502060746"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins> */}
      {bookPDF ? (
        <Book bookPDF={bookPDF} language={language} translation={translation} />
      ) : (
        <SelectBookPage
          setBookPDF={setBookPDF}
          setLanguage={setLanguage}
          setTranslation={setTranslation}
        />
      )}
    </div>
  );
}

const langOptions = [
  { value: "en-US", label: "English (US)" },
  { value: "en-GB", label: "English (UK)" },
  // { value: "es-ES", label: "Español (España)" },
  // { value: "fr-FR", label: "Français" },
  // { value: "de-DE", label: "Deutsch" },
  // { value: "it-IT", label: "Italiano" },
  // { value: "nl-NL", label: "Nederlands" },
  { value: "en-IN", label: "English (India)" },
  // { value: "pt-br", label: "Português (Brasil)" },
  { value: "en-CA", label: "English (Canada)" },
  { value: "en-AU", label: "English (Australia)" },
  // { value: "es-MX", label: "English (México)" },
  // { value: "pt-PT", label: "Português (Portugal)" },
  // { value: "sk", label: "Slovenčina" },
  // { value: "pl", label: "Polski" },
  // { value: "ro-RO", label: "Română" },
  // { value: "fi", label: "Suomi" },
  // { value: "no-NO", label: "Norsk" },
  // { value: "cs", label: "Čeština" },
  // { value: "es-AR", label: "Español (Argentina)" },
  // { value: "es-CO", label: "Español (Colombia)" },
  // { value: "es-CL", label: "Español (Chile) " },
  // { value: "es-PE", label: "Español (Perú)" },
  // { value: "es-VE", label: "Español (Venezuela)" },
  // { value: "es-EC", label: "Español (Ecuador)" },
  // { value: "es-GT", label: "Español (Guatemala)" },
  // { value: "es-CU", label: "Español (Cuba)" },
  // { value: "es-HN", label: "Español (Honduras) " },
  // { value: "es-PY", label: "Español (Paraguay)" },
  // { value: "es-BO", label: "Español (Bolivia) " },
  // { value: "es-UY", label: "Español (Uruguay)" },
  // { value: "es-NI", label: "Español (Nicaragua) " },
  // { value: "es-SV", label: "Español (El Salvador)" },
  // { value: "es-CR", label: "Español (Costa Rica) " },
  // { value: "es-PA", label: "Español (Panamá) " },
  // { value: "es-DO", label: "Español (República Dominicana)" },
];

const langToTranslateFormat = {
  "en-US": "en",
  "en-GB": "en",
  "es-ES": "es",
  "fr-FR": "fr",
  "de-DE": "de",
  "it-IT": "it",
  "nl-NL": "nl",
  "en-IN": "en",
  "pt-br": "pt",
  "en-CA": "en",
  "en-AU": "en",
  "es-MX": "es",
  "pt-PT": "pt",
  sk: "sk",
  pl: "pl",
  "ro-RO": "ro",
  fi: "fi",
  "no-NO": "no",
  cs: "cs",
  "es-AR": "es",
  "es-CO": "es",
  "es-CL": "es",
  "es-PE": "es",
  "es-VE": "es",
  "es-EC": "es",
  "es-GT": "es",
  "es-CU": "es",
  "es-HN": "es",
  "es-PY": "es",
  "es-BO": "es",
  "es-UY": "es",
  "es-NI": "es",
  "es-SV": "es",
  "es-CR": "es",
  "es-PA": "es",
  "es-DO": "es",
};

const translateOptions = [
  { value: "none", label: "No translation" },
  { value: "af", label: "Afrikaans" },
  { value: "sq", label: "Albanian" },
  { value: "ar", label: "Arabic" },
  { value: "az", label: "Azerbaijani" },
  { value: "bn", label: "Bengali" },
  { value: "bg", label: "Bulgarian" },
  { value: "ca", label: "Catalan" },
  { value: "zh-CN", label: "Chinese (Simplified)" },
  { value: "zh-TW", label: "Chinese (Traditional)" },
  { value: "hr", label: "Croatian" },
  { value: "cs", label: "Czech" },
  { value: "da", label: "Danish" },
  { value: "nl", label: "Dutch" },
  { value: "et", label: "Estonian" },
  { value: "fi", label: "Finnish" },
  { value: "fr", label: "French" },
  { value: "gl", label: "Galician" },
  { value: "ka", label: "Georgian" },
  { value: "de", label: "German" },
  { value: "el", label: "Greek" },
  { value: "gu", label: "Gujarati" },
  { value: "ht", label: "Haitian Creole" },
  { value: "iw", label: "Hebrew" },
  { value: "hi", label: "Hindi" },
  { value: "hu", label: "Hungarian" },
  { value: "is", label: "Icelandic" },
  { value: "id", label: "Indonesian" },
  { value: "it", label: "Italian" },
  { value: "ja", label: "Japanese" },
  { value: "ko", label: "Korean" },
  { value: "lv", label: "Latvian" },
  { value: "lt", label: "Lithuanian" },
  { value: "ms", label: "Malay" },
  { value: "mr", label: "Marathi" },
  { value: "no", label: "Norwegian" },
  { value: "fa", label: "Persian" },
  { value: "pl", label: "Polish" },
  { value: "pt", label: "Portuguese" },
  { value: "pa", label: "Punjabi" },
  { value: "ro", label: "Romanian" },
  { value: "ru", label: "Russian" },
  { value: "sr", label: "Serbian" },
  { value: "sk", label: "Slovak" },
  { value: "sl", label: "Slovenian" },
  { value: "es", label: "Spanish" },
  { value: "sw", label: "Swahili" },
  { value: "sv", label: "Swedish" },
  { value: "th", label: "Thai" },
  { value: "tr", label: "Turkish" },
  { value: "uk", label: "Ukrainian" },
  { value: "ur", label: "Urdu" },
  { value: "vi", label: "Vietnamese" },
  { value: "cy", label: "Welsh" },
];

const selectStyle = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    width: isMobile ? "80vw" : "60vw",
    marginTop: isMobile ? 16 : 24,
    backgroundColor: colors.white,
    padding: isMobile ? 0 : 8,
    cursor: "pointer",
  }),
  option: (style) => ({
    ...style,
    backgroundColor: colors.white,
    color: colors.primary,
    borderBottom: 1,
    cursor: "pointer",
  }),
  placeholder: (style) => ({
    ...style,
    color: colors.secondary,
  }),
};

const buttonStyle = {
  backgroundColor: colors.primary,
  color: colors.white,
  fontSize: isMobile ? 32 : 32,
  padding: "8px 0px",
  width: "calc(100%)",
  cursor: "pointer",
  marginTop: isMobile ? 16 : 16,
  borderRadius: 6,
};

function SelectBookPage({ bookPDF, setBookPDF, setLanguage, setTranslation }) {
  const [selectedLang, setSelectedLang] = useState();
  const [selectedTranslation, setSelectedTranslation] = useState("none");
  const [tempBookPDF, setTempBookPDF] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        maxWidth: "100%",
        overflowX: "hidden",
        display: "flex",
        justifyContent: "space-around",
        backgroundColor: colors.secondary,
      }}
    >
      {/* {!isMobile && (
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-2862497790933982"
          data-ad-slot="7374542650"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      )} */}
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: isMobile ? "0px 10%" : "0px 20%",
          color: colors.white,
          fontSize: 24,
        }}
      >
        <h1 style={{ textAlign: "center", fontSize: isMobile ? 32 : 48 }}>
          <Fade top cascade>
            Pronounce Right
          </Fade>
        </h1>
        <Select
          styles={selectStyle}
          value={selectedLang}
          onChange={(selectedOption) => setSelectedLang(selectedOption)}
          options={langOptions}
          isSearchable={true}
          placeholder="Select pdf's language"
        />
        <Select
          styles={selectStyle}
          value={selectedTranslation}
          onChange={(selectedOption) => setSelectedTranslation(selectedOption)}
          options={translateOptions}
          isSearchable={true}
          placeholder="Select to translate"
        />
        <SelectBookInput
          tempBookPDF={tempBookPDF}
          setTempBookPDF={setTempBookPDF}
        />
        <button
          style={buttonStyle}
          onClick={() => {
            if (!selectedLang) return alert("Choose the language first");
            if (!tempBookPDF) return alert("Choose the book first");

            setLanguage(selectedLang.value);
            setTranslation(
              selectedTranslation == "none" ? "none" : selectedTranslation.value
            );

            setBookPDF(tempBookPDF);
            setTempBookPDF(null);
            setSelectedLang();
            setSelectedTranslation("none");
          }}
        >
          Read
        </button>
        <button
          style={{ ...buttonStyle, fontSize: 24 }}
          onClick={() => {
            window.open(
              "https://patreon.com/PronounceRight?utm_medium=clipboard_copy&utm_source=copyLink&utm_campaign=creatorshare_creator&utm_content=join_link"
            );
          }}
        >
          Support on Patreon for new updates and ad-free experience for everyone
        </button>

        <h3 style={{ textAlign: "center", marginTop: isMobile ? 16 : 24 }}>
          Reload the page to close the book!
        </h3>
        {/* <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-format="autorelaxed"
          data-ad-client="ca-pub-2862497790933982"
          data-ad-slot="2267037279"
        ></ins> */}
      </div>
      {/* {!isMobile && (
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-2862497790933982"
          data-ad-slot="7374542650"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      )} */}
    </div>
  );
}

const SelectBookInput = ({ tempBookPDF, setTempBookPDF }) => {
  return (
    <div
      style={{
        marginTop: isMobile ? 16 : 24,
        width: "calc(100%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.white,
        height: isMobile ? 200 : 300,
        color: colors.primary,
        position: "relative",
        borderRadius: 6,
        cursor: "pointer",
      }}
    >
      <FontAwesomeIcon
        icon={tempBookPDF ? faBook : faFilePdf}
        color={colors.primary}
        size="3x"
      />
      <b style={{ marginTop: 16, textAlign: "center" }}>
        {tempBookPDF ? tempBookPDF.name : "select the book in pdf format"}
      </b>
      <input
        type="file"
        accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: "0",
          cursor: "pointer",
        }}
        onChange={(ev) => {
          setTempBookPDF(ev.target.files[0]);
        }}
      />
    </div>
  );
};

// 0 = nothing, 1 = done, 2 = jumped

async function loadBook(pdf, pageNumber, setWords, setTotalPages) {
  // const pdf = await pdfjs.getDocument(URL.createObjectURL(bookPDF)).promise;
  setTotalPages(pdf.numPages);
  const page = await pdf.getPage(pageNumber);
  let tempChars = await page.getTextContent();
  let chars = [];
  tempChars.items.forEach((char) => {
    if (char.str == "") chars.push(char.str);
    for (let i = 0; i < char.str.length; i++) {
      chars.push(char.str[i]);
    }
  });

  let words = [];
  let wordAux = "";

  chars.forEach((char, i) => {
    if (char == " ") {
      words.push([wordAux, 0]);
      wordAux = "";
    } else {
      if (char == "") {
        words.push([wordAux, 0]);
        words.push(["@#", 0]);
        wordAux = "";
      }
      wordAux += char;
    }
  });

  console.log(words);
  setWords(words);
}

const AndroidRestricitonModal = ({ setAndroidRestrictionModalIsVisible }) => {
  const [isUnderstanding, setIsUnderstanding] = useState(false);
  return (
    <Modal header={<div />}>
      <div style={{ fontSize: 20 }}>
        <h3>
          Android doesn't support pronounce checking, try using on PC or IOS to
          able this feature
        </h3>

        <p style={{ marginTop: 8 }}>
          you are still able to use the other features
        </p>

        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            checked={isUnderstanding}
            onChange={() => setIsUnderstanding(!isUnderstanding)}
          />
          <span style={{ marginLeft: 4 }}>Do not show me again</span>
        </div>
        <button
          style={buttonStyle}
          onClick={() => {
            if (isUnderstanding)
              localStorage.setItem("understandAndroidRestrictions", true);
            setAndroidRestrictionModalIsVisible(false);
          }}
        >
          Got it
        </button>
      </div>
    </Modal>
  );
};

const Book = ({ bookPDF, language, translation }) => {
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [lastWordTalked, setLasWordTalked] = useState("");
  const [loadedBook, setLoadedBook] = useState();
  const [isSpeaking, setIsSpeaking] = useState(false);

  let [words, setWords] = useState([]);
  const [actualWordIndex, setActualWordIndex] = useState(1);

  const [selectedWord, setSelectedWord] = useState("");
  const [selectedContext, setSelectedContext] = useState("");
  const [browserNotSupportSpeech, setBrowserNotSupportSpeech] = useState(true);
  const [
    androidRestrictionModalIsVisible,
    setAndroidRestrictionModalIsVisible,
  ] = useState(false);

  useEffect(() => {
    if (pageNumber == 1) {
      const lastPage = localStorage.getItem(bookPDF.name);
      if (lastPage) {
        const answer = window.confirm(
          `Would you like to go back to page ${lastPage}?`
        );
        if (answer) setPageNumber(Number(lastPage));
      }
    }

    pdfjs
      .getDocument(URL.createObjectURL(bookPDF))
      .promise.then((pdf) => setLoadedBook(pdf));
  }, []);

  useEffect(() => {
    if (loadedBook) {
      loadBook(loadedBook, pageNumber, setWords, setTotalPages);
      if (pageNumber != 1) {
        localStorage.setItem(bookPDF.name, pageNumber);
      }
    }
  }, [pageNumber, loadedBook]);

  useEffect(() => {
    if (words.length != 0) {
      let auxActualWordIndex = actualWordIndex;

      while (words[auxActualWordIndex][0] == "@#") {
        auxActualWordIndex++;
      }

      if (isNum(words[auxActualWordIndex][0]))
        words[auxActualWordIndex][0] = numberConverter.toWords(
          words[auxActualWordIndex][0]
        );
      if (
        words[auxActualWordIndex][0]
          .replace(language != "fr-FR" ? /[.!?"',:;]/g : /[.!?",:;]/g, "")
          .toLowerCase() == lastWordTalked.toLowerCase()
      ) {
        words[auxActualWordIndex][1] = 1;
        setActualWordIndex(auxActualWordIndex + 1);
        setWords(words);
      }
    }
  }, [lastWordTalked]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {androidRestrictionModalIsVisible && (
        <AndroidRestricitonModal
          setAndroidRestrictionModalIsVisible={
            setAndroidRestrictionModalIsVisible
          }
        />
      )}

      {(language == "en-US" ||
        language == "en-GB" ||
        language == "en-IN" ||
        language == "en-CA" ||
        language == "en-AU") &&
        selectedWord && (
          <DictionaryModal
            word={selectedWord}
            context={selectedContext}
            setWord={setSelectedWord}
            language={language}
            translation={translation}
            isMobile={isMobile}
          />
        )}
      <div
        style={{
          display: "flex",
          padding: "24px 24px 8px 24px",
          width: "calc(100vw - 48px)",
          overflowY: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              height: `calc(100vh)`,
              width: "100%",
              flexWrap: "wrap",
              overflowY: "auto",
            }}
          >
            {words.map(([word, status], i) => {
              if (word !== "@#") {
                return (
                  <span
                    key={`word_${i}`}
                    style={{
                      color: "transparent",
                      backgroundImage: `linear-gradient(90deg, ${
                        status == 0
                          ? colors.black
                          : status == 1
                          ? colors.green
                          : colors.yellow
                      } 50%, ${colors.black} 50%)`,
                      backgroundPosition: status == 0 ? "100%" : 1,
                      backgroundSize: "200% 100%",
                      backgroundClip: "text",
                      transition: "background-position 200ms ease",

                      marginTop: 10,
                      marginLeft: 5,
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      if (
                        language == "en-US" ||
                        language == "en-GB" ||
                        language == "en-IN" ||
                        language == "en-CA" ||
                        language == "en-AU"
                      ) {
                        setSelectedWord(
                          word.replace(
                            language != "fr-FR" ? /[.!?"',:;]/g : /[.!?",:;]/g,
                            ""
                          )
                        );
                        let context = "";
                        if (i >= 5) {
                          context += words
                            .slice(i - 5, i)
                            .map((w) => w[0])
                            .join(" ");
                        }
                        context += " ";
                        if (i + 5 < words.length) {
                          context += words
                            .slice(i, i + 5)
                            .map((w) => w[0])
                            .join(" ");
                        }

                        if (i == words.length - 1)
                          context += words[words.length - 1][0];

                        setSelectedContext(context.replace(/@#/g, " "));
                      } else {
                        const synth = window.speechSynthesis;

                        const u = new SpeechSynthesisUtterance(word);
                        u.lang = language;
                        if (!isSpeaking) {
                          synth.cancel();
                        }
                        setIsSpeaking(true);
                        await synth.speak(u);
                        setIsSpeaking(false);
                      }
                    }}
                  >
                    {word}
                  </span>
                );
              } else {
                return (
                  <div
                    key={`word_${i}`}
                    style={{ height: 20, width: "100%" }}
                  />
                );
              }
            })}
          </div>
          <div
            style={{ height: isMobile && browserNotSupportSpeech ? 108 : 66 }}
          />
          <div
            style={{
              position: "fixed",
              bottom: 0,
              display: "flex",
              justifyContent: browserNotSupportSpeech
                ? "space-between"
                : "center",
              left: 0,
              right: 0,
              flexWrap: "wrap",
              marginTop: 10,
              padding: "10px 24px",
              backgroundColor: colors.secondary,
              boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            {browserNotSupportSpeech && (
              <button
                style={{
                  backgroundColor: colors.primary,
                  color: colors.white,
                  fontSize: 24,
                  padding: "0px 16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  words[actualWordIndex][1] = 2;
                  setActualWordIndex(actualWordIndex + 1);
                  setWords(words);
                }}
              >
                jump word
              </button>
            )}

            <PageChanger
              page={pageNumber}
              setPage={setPageNumber}
              totalPages={totalPages}
              setActualWordIndex={setActualWordIndex}
            />
            <Dictaphone
              lastWordTalked={lastWordTalked}
              setLasWordTalked={setLasWordTalked}
              language={language}
              setBrowserNotSupportSpeech={setBrowserNotSupportSpeech}
              setAndroidRestrictionModalIsVisible={
                setAndroidRestrictionModalIsVisible
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

const DictionaryModal = ({
  word,
  context,
  setWord,
  language,
  translation,
  isMobile,
}) => {
  const [data, setData] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [translatedWord, setTranslatedWord] = useState("");

  useEffect(() => {
    // dictionary
    axios
      .get("https://api.dictionaryapi.dev/api/v2/entries/en/" + word)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log("Unexpected error " + err));

    // translation
    if (translation != "none") {
      axios
        .post(
          "https://translation.googleapis.com/language/translate/v2",
          {},
          {
            params: {
              q: word,
              format: "text",
              source: langToTranslateFormat[language],
              target: translation,
              key: process.env.REACT_APP_GOOGLE_KEY,
              context,
            },
          }
        )
        .then((response) => {
          setTranslatedWord(response.data.data.translations[0].translatedText);
        })
        .catch((err) => {
          console.log("translate api error", err);
          setTranslatedWord("");
        });
    }
  }, [word]);

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        zIndex: 5,
      }}
    >
      <Zoom duration={300}>
        <div
          style={{
            backgroundColor: colors.secondary,
            color: colors.white,
            width: isMobile ? "80%" : "70%",
            height: isMobile ? "70%" : "50%",
            overflowY: "auto",
            padding: 16,
            borderRadius: 6,
            zIndex: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1 style={{ marginRight: 16 }}>{word}</h1>
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faVolumeHigh}
                color={colors.white}
                fontSize="32px"
                onClick={async () => {
                  const synth = window.speechSynthesis;

                  const u = new SpeechSynthesisUtterance(word);
                  u.lang = language;
                  if (!isSpeaking) {
                    synth.cancel();
                  }
                  setIsSpeaking(true);
                  await synth.speak(u);
                  setIsSpeaking(false);
                }}
              />
            </div>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faClose}
              color={colors.white}
              fontSize="32px"
              onClick={() => setWord("")}
            />
          </div>

          <div style={{ marginTop: 16 }}>
            {translation != "none" && (
              <div style={{ marginTop: 16 }}>
                <h3
                  style={{
                    fontsize: 24,
                    marginBottom: 8,
                    fontWeight: "normal",
                  }}
                >
                  translation
                </h3>
                <b style={{ fontSize: 24, marginLeft: 8 }}>{translatedWord}</b>
              </div>
            )}

            {data.map((element) => {
              return element.meanings.map((meaning, i) => {
                return (
                  <div style={{ marginTop: 16 }} key={"definition" + i}>
                    <h3
                      style={{
                        fontsize: 24,
                        marginBottom: 8,
                      }}
                    >
                      definition ({meaning.partOfSpeech})
                    </h3>
                    {meaning.definitions.map(({ definition }, meaningIndex) => (
                      <ul
                        key={"meaning" + meaningIndex}
                        style={{ marginLeft: 8, marginTop: 8 }}
                      >
                        <li style={{ fontsize: 32 }}>{definition}</li>
                      </ul>
                    ))}
                  </div>
                );
              });
            })}
          </div>
        </div>
      </Zoom>

      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: colors.black,
          opacity: 0.3,
        }}
        onClick={() => setWord("")}
      />
    </div>
  );
};

const changePageEvent = (ev, page, setPage) => {
  switch (ev.key) {
    case "ArrowRight":
      setPage(page + 1);
      break;

    case "ArrowLeft":
      setPage(page - 1);
      break;

    default:
      break;
  }
};

const PageChanger = ({ page, setPage, totalPages, setActualWordIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",

        fontSize: 24,
        border: `solid 1px ${colors.primary}`,
      }}
    >
      <div
        style={{
          background: colors.primary,
          cursor: "pointer",
          padding: "0px 8px",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          if (page != 1) {
            setPage(page - 1);
            setActualWordIndex(1);
            window.scrollTo(0, 0);
          }
        }}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          color={colors.white}
          width={16}
          height={28}
        />
      </div>
      <div
        style={{
          width: 80,
          padding: "0px 8px",
          textAlign: "center",
          backgroundColor: colors.white,
        }}
      >
        {page}
      </div>
      <div
        style={{
          background: colors.primary,
          cursor: "pointer",
          padding: "0px 8px",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          if (page != totalPages) {
            setPage(page + 1);
            setActualWordIndex(1);
            window.scrollTo(0, 0);
          } else alert("You are in the last page");
        }}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          color={colors.white}
          width={16}
          height={28}
        />
      </div>
    </div>
  );
};

const Dictaphone = ({
  lastWordTalked,
  setLasWordTalked,
  language,
  setBrowserNotSupportSpeech,
  setAndroidRestrictionModalIsVisible,
}) => {
  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    browserSupportsContinuousListening,
  } = useSpeechRecognition();

  useEffect(() => {
    const words = transcript.split(" ");
    setLasWordTalked(words[words.length - 1]);
  }, [transcript]);

  useEffect(() => {
    if (!listening && browserSupportsContinuousListening)
      SpeechRecognition.startListening({ continuous: true, language });

    if (!browserSupportsSpeechRecognition) {
      alert(
        "Browser doesn't support speech recognition, try uptading or changing it"
      );
      setBrowserNotSupportSpeech(false);
    }

    if (!browserSupportsContinuousListening) {
      let understandAndroidRestrictions = localStorage.getItem(
        "understandAndroidRestrictions"
      );
      if (!understandAndroidRestrictions)
        setAndroidRestrictionModalIsVisible(true);

      setBrowserNotSupportSpeech(false);
    }
  }, []);

  if (!browserSupportsContinuousListening || !browserSupportsSpeechRecognition)
    return <></>;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: isMobile ? 8 : 0,
      }}
    >
      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (listening) {
            SpeechRecognition.stopListening();
          } else {
            if (browserSupportsContinuousListening) {
              SpeechRecognition.startListening({ continuous: true, language });
            } else {
              alert("Android doesn't support shadow reading");
            }
          }
        }}
        size="2x"
        icon={listening ? faMicrophone : faMicrophoneSlash}
        color={colors.primary}
      />
      <span
        style={{
          fontSize: 24,
          paddingLeft: 10,
          width: 150,
          color: colors.white,
        }}
      >
        {!listening
          ? "Unmute to speak"
          : lastWordTalked
          ? lastWordTalked
          : "Speak to start"}
      </span>
    </div>
  );
};

export default App;
