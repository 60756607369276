const colors = {
  primary: "#22223B",
  secondary: "#4A4E69",
  green: "#598B2C",
  yellow: "#F6E27F",
  white: "#FAFAFF",
  black: "#090910",
};

export default colors;
