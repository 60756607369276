import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import colors from "../colors";
import { isMobile } from "react-device-detect";
import Zoom from "react-reveal/Zoom";

export default function Modal({ header, children, onClose, fixedHeight }) {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        zIndex: 5,
      }}
    >
      <Zoom duration={300}>
        <div
          style={{
            backgroundColor: colors.secondary,
            color: colors.white,
            width: isMobile ? "80%" : "70%",
            height: fixedHeight ? (isMobile ? "70%" : "50%") : "auto",
            overflowY: "auto",
            padding: 16,
            borderRadius: 6,
            zIndex: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {header}
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faClose}
              color={colors.white}
              fontSize="32px"
              onClick={onClose}
            />
          </div>

          {children}
        </div>
      </Zoom>

      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: colors.black,
          opacity: 0.3,
        }}
        onClick={onClose}
      />
    </div>
  );
}
